import { IOptions } from "vanilla-calendar-pro/types"
import { $App } from "../.."
import Scene from "./scene"
import VanillaCalendar from "vanilla-calendar-pro"
import gsap from "gsap"

export default class Home {
  app: $App
  scene: Scene
  constructor(app: $App) {
    this.app = app
  }

  create() {
    this.createDateTime()
  }

  createForm(plan: string, dt: number) {
    const form = window.$(".hero__form") as unknown as HTMLFormElement

    const seat = window.$("#seat") as unknown as HTMLInputElement
    const name = window.$("#name") as unknown as HTMLInputElement
    const email = window.$("#email") as unknown as HTMLInputElement
    const duration = window.$(".twoo input") as unknown as HTMLInputElement

    const btn = window.$(".hero__submit")
    form.addEventListener("submit", async (e) => {
      e.preventDefault()
      btn.classList.add("active")

      try {
        const api = await fetch("https://api.thegarage.ng/book", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            seat: seat.value,
            plan: plan,
            name: name.value,
            email: email.value,
            gender: "",
            customTime: duration.valueAsNumber,
            phone: 1,
            beginsAt: dt,
          }),
        })
        if (api.ok) {
          const data = await api.json()
          window.location.assign(data.payment_url)
          btn.classList.remove("active")
        }
      } catch {
        btn.classList.remove("active")
        btn.innerHTML = `RETRY <img src="/images/loading.svg"/>`
      } finally {
      }
    })
  }

  createScene() {
    this.scene = new Scene()
  }

  createDateTime() {
    const time = document.querySelector("#timer")
    const $date = new Date()
    let selected: string
    let ddt: Date
    const options: IOptions = {
      input: true,
      settings: {
        selection: {
          time: true, // or 12
        },
        visibility: {
          theme: "light",
          weekend: false,
          today: false,
        },
        range: {
          min: `${new Date().getFullYear()}-${
            $date.getMonth() + 1 === 1
              ? "01"
              : $date.getMonth() + 1 === 2
              ? "02"
              : $date.getMonth() + 1 === 3
              ? "03"
              : $date.getMonth() + 1 === 4
              ? "04"
              : $date.getMonth() + 1 === 5
              ? "05"
              : $date.getMonth() + 1 === 6
              ? "06"
              : $date.getMonth() + 1 === 7
              ? "07"
              : $date.getMonth() + 1 === 8
              ? "08"
              : $date.getMonth() + 1 === 9
              ? "09"
              : $date.getMonth() + 1 === 10
              ? "10"
              : $date.getMonth() + 1 === 11
              ? "11"
              : "12"
          }-${
            $date.getDate() === 1
              ? "01"
              : $date.getDate() === 2
              ? "02"
              : $date.getDate() === 3
              ? "03"
              : $date.getDate() === 4
              ? "04"
              : $date.getDate() === 5
              ? "05"
              : $date.getDate() === 6
              ? "06"
              : $date.getDate() === 7
              ? "07"
              : $date.getDate() === 8
              ? "08"
              : $date.getDate() === 9
              ? "09"
              : $date.getDate() === 10
              ? "10"
              : $date.getDate() === 11
              ? "11"
              : $date.getDate() === 12
              ? "12"
              : $date.getDate() === 13
              ? "13"
              : $date.getDate() === 14
              ? "14"
              : $date.getDate() === 15
              ? "15"
              : $date.getDate() === 16
              ? "16"
              : $date.getDate() === 17
              ? "17"
              : $date.getDate() === 18
              ? "18"
              : $date.getDate() === 19
              ? "19"
              : $date.getDate() === 20
              ? "20"
              : $date.getDate() === 21
              ? "21"
              : $date.getDate() === 22
              ? "22"
              : $date.getDate() === 23
              ? "23"
              : $date.getDate() === 24
              ? "24"
              : $date.getDate() === 25
              ? "25"
              : $date.getDate() === 26
              ? "26"
              : $date.getDate() === 27
              ? "27"
              : $date.getDate() === 28
              ? "28"
              : $date.getDate() === 29
              ? "29"
              : $date.getDate() === 30
              ? "30"
              : "31"
          }`,
          max: `${new Date().getFullYear()}-${
            $date.getMonth() + 2 === 13
              ? "01"
              : $date.getMonth() + 2 === 2
              ? "02"
              : $date.getMonth() + 2 === 3
              ? "03"
              : $date.getMonth() + 2 === 4
              ? "04"
              : $date.getMonth() + 2 === 5
              ? "05"
              : $date.getMonth() + 2 === 6
              ? "06"
              : $date.getMonth() + 2 === 7
              ? "07"
              : $date.getMonth() + 2 === 8
              ? "08"
              : $date.getMonth() + 2 === 9
              ? "09"
              : $date.getMonth() + 2 === 10
              ? "10"
              : $date.getMonth() + 2 === 11
              ? "11"
              : "12"
          }-${
            $date.getDate() === 1
              ? "01"
              : $date.getDate() === 2
              ? "02"
              : $date.getDate() === 3
              ? "03"
              : $date.getDate() === 4
              ? "04"
              : $date.getDate() === 5
              ? "05"
              : $date.getDate() === 6
              ? "06"
              : $date.getDate() === 7
              ? "07"
              : $date.getDate() === 8
              ? "08"
              : $date.getDate() === 9
              ? "09"
              : $date.getDate() === 10
              ? "10"
              : $date.getDate() === 11
              ? "11"
              : $date.getDate() === 12
              ? "12"
              : $date.getDate() === 13
              ? "13"
              : $date.getDate() === 14
              ? "14"
              : $date.getDate() === 15
              ? "15"
              : $date.getDate() === 16
              ? "16"
              : $date.getDate() === 17
              ? "17"
              : $date.getDate() === 18
              ? "18"
              : $date.getDate() === 19
              ? "19"
              : $date.getDate() === 20
              ? "20"
              : $date.getDate() === 21
              ? "21"
              : $date.getDate() === 22
              ? "22"
              : $date.getDate() === 23
              ? "23"
              : $date.getDate() === 24
              ? "24"
              : $date.getDate() === 25
              ? "25"
              : $date.getDate() === 26
              ? "26"
              : $date.getDate() === 27
              ? "27"
              : $date.getDate() === 28
              ? "28"
              : $date.getDate() === 29
              ? "29"
              : $date.getDate() === 30
              ? "30"
              : "31"
          }`,
        },
      },
      actions: {
        clickDay(event, self) {
          ddt = new Date(
            (self.selectedDates + " " + self.selectedTime).replace(/-/g, "/")
          )
          btn.innerHTML = `VIEW AVAILABLE SEATS <img src="/images/loading.svg"/>`
        },
        changeTime(event, self) {
          ddt = new Date(
            (self.selectedDates + " " + self.selectedTime).replace(/-/g, "/")
          )
          // hours = New Date(self.selectedTime)
          btn.innerHTML = `VIEW AVAILABLE SEATS <img src="/images/loading.svg"/>`
          time.innerHTML = self.selectedTime
        },
        changeToInput(e, self) {
          if (!self.HTMLInputElement) return
          if (self.selectedDates[0]) {
            self.HTMLInputElement.value = self.selectedDates[0]
            ddt = new Date(
              (self.selectedDates + " " + self.selectedTime).replace(/-/g, "/")
            )
            time.innerHTML = self.selectedTime
            // self.hide()
          } else {
            self.HTMLInputElement.value = ""
            selected = ""
            // self.hide()
          }
        },
        initCalendar(self) {
          const btnEl = self.HTMLElement.querySelector("#btn-close")
          if (!btnEl) return
          btnEl.addEventListener("click", self.hide)
        },
      },
      DOMTemplates: {
        default: `
          <div class="vanilla-calendar-header">
            <#ArrowPrev />
            <div class="vanilla-calendar-header__content">
              <#Month />
              <#Year />
            </div>
            <#ArrowNext />
          </div>
          <div class="vanilla-calendar-wrapper">
            <#WeekNumbers />
            <div class="vanilla-calendar-content">
              <#Week />
              <#Days />
            </div>
          </div>
          <#ControlTime />
          <button id="btn-close" type="button">Close</button>
        `,
      },
    }

    const calendar = new VanillaCalendar("#calendar-input", options)
    calendar.init()

    const pre = window.$(".pre")
    const plan = window.$("#setplan") as unknown as HTMLSelectElement
    const range = window.$(".pre__input.two")
    const date = window.$("#date") as unknown as HTMLInputElement
    // const time = window.$("#time") as unknown as HTMLInputElement
    const calendarEl = window.$(".vanilla-calendar") as HTMLElement
    const duration = window.$(".twoo") as unknown as HTMLInputElement
    const form = window.$(".pre__form") as unknown as HTMLFormElement
    const btn = window.$(".pre__submit")

    // time.addEventListener("change", () => {
    //   btn.classList.remove("active")
    //   btn.innerHTML = `VIEW AVAILABLE SEATS <img src="/images/loading.svg"/>`
    // })

    plan.addEventListener("change", () => {
      const val = plan.value
      if (val === "day" || val === "night") {
        document.body?.classList.add("notime")
      } else {
        document.body?.classList.remove("notime")
      }
      if (val === "custom") {
        duration.classList.add("active")
      } else {
        duration.classList.remove("active")
      }
    })

    form.addEventListener("submit", async (e) => {
      e.preventDefault()
      let dur: number
      if (plan.value === "1hr") {
        dur = 1
      } else if (plan.value === "4hr") {
        dur = 4
      } else if (plan.value === "day") {
        dur = 7
      } else if (plan.value === "night") {
        dur = 9
      } else if (plan.value === "custom") {
        dur = duration.querySelector("input").valueAsNumber
      }

      const dt = new Date(ddt ?? Date.now())
      // dt.setMinutes(time.valueAsDate?.getMinutes())
      // dt.setMinutes(0)
      // dt.setSeconds(0)
      // dt.setMilliseconds(0)

      if (!ddt || isNaN(ddt.getTime())) {
        btn.classList.add("active")
        await gsap.delayedCall(2, () => {
          btn.classList.remove("active")
          btn.innerHTML = `SELECT A VALID DATE AND TIME <img src="/images/loading.svg"/>`
        })
        return
      }

      const edt = new Date(dt)

      const getTime = (valid?: number) => {
        if (plan.value === "day" || plan.value === "night") return valid
        return edt.getHours() - 1
      }

      if (plan.value === "1hr") {
        edt.setHours(dt?.getHours() + 1)
      } else if (plan.value === "4hr") {
        edt.setHours(dt?.getHours() + 4)
      } else if (plan.value === "day") {
        dt.setHours(8)
        edt.setHours(dt?.getHours() + 13)
        dt.setMinutes(0)
        edt.setMinutes(0)
      } else if (plan.value === "night") {
        dt.setHours(21)
        edt.setHours(dt?.getHours() + 9)
        dt.setMinutes(0)
        edt.setMinutes(0)
      } else if (plan.value === "custom") {
        edt.setHours(
          dt?.getHours() +
            duration.querySelector<HTMLInputElement>("input[type='number']")
              .valueAsNumber
        )
      }

      if (
        //start is in the past
        dt.getTime() < new Date().getTime() ||
        //time input is less than 7am
        getTime(8) < 7 ||
        //time input is greater than 8pm
        getTime(17) > 20 ||
        //time input plus duration is past 9pm
        getTime(-10) + dur > 22
      ) {
        btn.classList.add("active")
        await gsap.delayedCall(2, () => {
          btn.classList.remove("active")
          btn.innerHTML = `TIME RANGE UNAVAILABLE <img src="/images/loading.svg"/>`
        })
        return
      }

      btn.classList.add("active")
      try {
        const fetched = await fetch(
          // `http://localhost:8000/bookedseats?startTime=${dt}&endTime=${61717189191}`
          `https://api.thegarage.ng/bookedseats?startTime=${dt.getTime()}&endTime=${edt.getTime()}`
        )
        const json = await fetched.json()
        window.api.books = json
        this.createScene()
        pre.classList.add("inactive")
        btn.classList.remove("active")
        this.createForm(plan.value, dt.getTime())
      } catch {
        btn.classList.remove("active")
        btn.innerHTML = `RETRY <img src="/images/loading.svg"/>`
        // window.api.books = [{ seat: "A1" }, { seat: "C2" }]
      } finally {
      }
    })
  }

  resize() {}

  destroy() {}

  navigate() {}
}
